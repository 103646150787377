import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = { class: "d-flex align-items-center ms-1 ms-lg-3" }
const _hoisted_3 = {
  class: "btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_4 = { class: "svg-icon svg-icon-1" }
const _hoisted_5 = { class: "d-flex align-items-center ms-1 ms-lg-3" }
const _hoisted_6 = {
  class: "btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1" }
const _hoisted_8 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_9 = {
  class: "cursor-pointer symbol symbol-30px symbol-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTQuickLinksMenu = _resolveComponent("KTQuickLinksMenu")!
  const _component_KTNotificationsMenu = _resolveComponent("KTNotificationsMenu")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen025.svg" })
        ])
      ]),
      _createVNode(_component_KTQuickLinksMenu)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen022.svg" })
        ])
      ]),
      _createVNode(_component_KTNotificationsMenu)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("img", {
          src: _ctx.UserUtils.generateGravatarUrl(_ctx.email),
          alt: "metronic"
        }, null, 8, _hoisted_10)
      ]),
      _createVNode(_component_KTUserMenu, {
        username: _ctx.username,
        email: _ctx.email,
        "avatar-url": _ctx.UserUtils.generateGravatarUrl(_ctx.email)
      }, null, 8, ["username", "email", "avatar-url"])
    ])
  ]))
}