
import { computed, defineComponent } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import {
  themeName,
  headerWidthFluid,
  headerLeft,
  asideDisplay,
  themeLogoIcon,
} from "@/core/helpers/config";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import { useStore } from "vuex";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
  },
  setup() {
    const store = useStore();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    const breadcrumbRoutes = computed(() => {
      return store.getters.pageBreadcrumbRoute;
    });

    const getBreadcrumbRoutesLength = computed(() => {
      return breadcrumbRoutes.value ? breadcrumbRoutes.value.length : 0;
    });

    const getBreadcrumbRoute = (index) => {
      if (
        breadcrumbRoutes.value &&
        index + 1 <= getBreadcrumbRoutesLength.value
      ) {
        return breadcrumbRoutes.value[index];
      } else {
        return "";
      }
    };

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      themeName,
      themeLogoIcon,
      pageTitle,
      breadcrumbs,
      breadcrumbRoutes,
      getBreadcrumbRoute,
      I18nUtils,
    };
  },
});
