
import { defineComponent } from "vue";
import { useStore } from "vuex";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { UserUtils } from "@/sea/utils/UserUtils";

export default defineComponent({
  name: "topbar",
  components: {
    KTNotificationsMenu,
    KTQuickLinksMenu,
    KTUserMenu,
  },
  setup() {
    const store = useStore();

    const username = store.getters.currentUser?.username;
    const email = store.getters.currentUser?.email;

    return {
      username,
      email,
      UserUtils,
    };
  },
});
