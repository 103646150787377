const MainMenuConfig = [
  {
    pages: [
      {
        heading: "sea.aside.categories.dashboard.title",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "sea.aside.categories.order.title",
    pages: [
      {
        heading: "sea.aside.categories.order.items.orders",
        route: "/orders",
        svgIcon: "media/icons/duotune/ecommerce/ecm008.svg",
        fontIcon: "bi-cart4",
      },
    ],
  },
  {
    heading: "sea.aside.categories.product.title",
    pages: [
      {
        heading: "sea.aside.categories.product.items.products",
        route: "/products",
        svgIcon: "media/icons/duotune/ecommerce/ecm005.svg",
        fontIcon: "bi-box-seam",
      },
      {
        heading: "sea.aside.categories.product.items.productCategories",
        route: "/product-categories",
        svgIcon: "media/icons/duotune/ecommerce/ecm009.svg",
        fontIcon: "bi-card-list",
      },
    ],
  },
  {
    heading: "sea.aside.categories.management.title",
    pages: [
      {
        heading: "sea.aside.categories.order.items.statistics",
        route: "/statistics",
        svgIcon: "media/icons/duotune/graphs/gra007.svg",
        fontIcon: "bi-pie-chart-fill",
      },
      {
        heading: "sea.aside.categories.management.items.administrators",
        route: "/administrators",
        svgIcon: "media/icons/duotune/general/gen049.svg",
        fontIcon: "bi-people-fill",
      },
      {
        heading: "sea.aside.categories.management.items.branches",
        route: "/branches",
        svgIcon: "media/icons/duotune/ecommerce/ecm004.svg",
        fontIcon: "bi-shop",
      },
    ],
  },
  {
    heading: "To Be Removed",
    pages: [
      {
        sectionTitle: "Pages",
        route: "/pages",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            sectionTitle: "Profile",
            route: "/profile",
            sub: [
              {
                heading: "Overview",
                route: "/crafted/pages/profile/overview",
              },
              {
                heading: "Projects",
                route: "/crafted/pages/profile/projects",
              },
              {
                heading: "Campaigns",
                route: "/crafted/pages/profile/campaigns",
              },
              {
                heading: "Documents",
                route: "/crafted/pages/profile/documents",
              },
              {
                heading: "Connections",
                route: "/crafted/pages/profile/connections",
              },
              {
                heading: "Activity",
                route: "/crafted/pages/profile/activity",
              },
            ],
          },
        ],
      },
      {
        sectionTitle: "Customers",
        route: "/customers",
        svgIcon: "media/icons/duotune/finance/fin006.svg",
        fontIcon: "bi-printer",
        sub: [
          {
            heading: "Getting Started",
            route: "/apps/customers/getting-started",
          },
          {
            heading: "Customers Listing",
            route: "/apps/customers/customers-listing",
          },
          {
            heading: "Customer Details",
            route: "/apps/customers/customer-details",
          },
        ],
      },
      {
        sectionTitle: "Subscriptions",
        route: "/subscriptions",
        svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
        fontIcon: "bi-cart",
        sub: [
          {
            heading: "Getting Started",
            route: "/subscriptions/getting-started",
          },
          {
            heading: "Subscription List",
            route: "/subscriptions/subscription-list",
          },
          {
            heading: "Add Subscription",
            route: "/subscriptions/add-subscription",
          },
          {
            heading: "View Subscription",
            route: "/subscriptions/view-subscription",
          },
        ],
      },
    ],
  },
];

export default MainMenuConfig;
