
import { defineComponent } from "vue";
import { footerWidthFluid, version, isProduction } from "@/core/helpers/config";
import moment from "moment-timezone";

export default defineComponent({
  name: "KTFooter",
  setup() {
    const year = moment().tz("Pacific/Auckland").year();
    const versionColor = isProduction ? "success" : "warning";

    return {
      footerWidthFluid,
      version,
      versionColor,
      year,
    };
  },
});
