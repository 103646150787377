
import { computed, defineComponent } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import { toolbarWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    breadcrumbRoutes: Array,
    title: String,
  },
  components: {
    Dropdown1,
  },
  setup(props) {
    const getBreadcrumbRoutesLength = computed(() => {
      return props.breadcrumbRoutes ? props.breadcrumbRoutes.length : 0;
    });

    const getBreadcrumbRoute = (index) => {
      if (
        props.breadcrumbRoutes &&
        index + 1 <= getBreadcrumbRoutesLength.value
      ) {
        return props.breadcrumbRoutes[index];
      } else {
        return "";
      }
    };

    return {
      I18nUtils,
      toolbarWidthFluid,
      getBreadcrumbRoute,
    };
  },
});
